import './sidebar.scss'
import react from 'react'
import { Link, useLocation } from 'react-router-dom'
import Intercom from '@intercom/messenger-js-sdk'
import { FaAnglesRight, FaList, FaCalculator, FaGears, FaSliders } from 'react-icons/fa6'
import { IoFileTrayFull } from 'react-icons/io5'
import { BsPersonBoundingBox } from 'react-icons/bs'
import { useAppState } from '../hooks'
import { FaRegQuestionCircle, FaTools, FaTruck, FaUsersCog } from 'react-icons/fa'

const SidebarLink = ({ to, Icon, label }) => {
  const loc = useLocation()

  return <div className={["sb-link", loc.pathname === to ? 'active' : ''].join(' ')}>
    <Link to={to}>
      <span className="icon"><Icon size={30} /></span>
      <span className="lbl">{label}</span>
    </Link>
  </div>
}

const IntercomWidget = ({ installer }) => {
  Intercom({
    app_id: 'u6xiqcxh',
    user_id: installer._id,
    name: installer.name || installer.email,
    email: installer.email,
    // created_at: user.createdAt, // sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
  })

  return <></>
}

export const Sidebar = ({ onToggle, isOpen }) => {
  const { registration: { installer }, tenant } = useAppState()

  return <div className="sidebar">
    <div className="sb-toggle-wrap">
      <div className="sb-toggle" onClick={() => {
        onToggle()
      }}>
        <FaAnglesRight size={30} className="angles" />
        <span className="lbl">Menu</span>
      </div>
    </div>
    <div className="sb-links">
      {installer && !installer.isConsumer ? <>
        <SidebarLink to="/calculator" Icon={FaCalculator} label="Loan Calculator" />
        <SidebarLink to="/projects" Icon={FaList} label="Projects" />
      </> : null}
      {installer?.isAdmin ? <SidebarLink to="/installer-admin" label="Installer Admin" Icon={FaUsersCog} /> : null}
      {tenant?.config?.trainingPageUrl && !installer?.isConsumer ? <SidebarLink to="/training" Icon={FaRegQuestionCircle} label="Training Materials" /> : null}
      {installer?.isConsumer ? <SidebarLink to="/applications" label="My Loan Applications" Icon={IoFileTrayFull} /> : null}
      {installer && !installer.isConsumer ? <IntercomWidget installer={installer} /> : null}
      {['super_admin', 'support', 'integrator'].includes(installer?.access)
        ? <div style={{ borderTop: '1px solid #dedede' }}>
          {installer.isIntegrator && <SidebarLink Icon={FaTruck} to="/installers" label="Installer Orgs" />}
          {installer.isSupport && <SidebarLink Icon={BsPersonBoundingBox} to="/prequal-reports" label="Prequal Reports" />}
          {installer.isSupport && <SidebarLink Icon={FaSliders} to="/dashboard" label="Tenant Settings" />}
          {installer.isSupport && process.env.REACT_APP_NODE_ENV === 'test' ? <SidebarLink Icon={FaTools} to="/tools" label="Tools" /> : null}
        </div>
        : null}
    </div>
  </div>
}
